.company-add-edit-main .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    /* height: 36px; */
    padding: 0px !important;
}

.company-add-edit-main .ant-upload.ant-upload-select {
    width: 100%;
}

.company-add-edit-main {
    overflow-x: hidden;
}

.company-add-edit-main .ant-select,
.company-add-edit-main .ant-input-number {
    /* border: 1px solid var(--primary); */
    width: 100%;
}

.company-add-edit-main .ant-input-number,
.company-add-edit-main .ant-input-number-input {
    height: 40px;
}

.primary-details-label {
    color: rgba(0, 0, 0, 0.85);
    font-family: 'NotoRegular';
}

.primary-details-form {
    margin-top: 1em !important;
}
.company-add-edit-main .ant-select-single .ant-select-selector .ant-select-selection-item,
.company-add-edit-main .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    font-family: NotoRegular;
   border: 1px solid #dedede;
}

.company-add-edit-main .document-type-card {
    margin: 3px;
}

.save-section {
    text-align: right;
}

.save-section .primary-button {
    width: 100px;
}

.company-add-edit-main {
    background: #f5f5f5;
}

.company-add-edit-main .ant-upload.ant-upload-select {
    width: 100%;
}

.company-add-edit-main .ant-picker {
    width: 100%;
    /* border: 1px solid var(--primary); */
    background: var(--white);
    /* height: 40px; */
}

.company-add-edit-main .rdw-image-imagewrapper img {
    height: 100px !important;
    width: 100px !important;
}

.company-add-edit-main .ant-form-item-label > label {
    font-family: NotoRegular;
}

.company-add-edit-main textarea.ant-input {
    /* border: 1px solid var(--primary) */
}

.company-add-edit-main .ant-select {
    border: 0px;
    /* border: 1px solid var(--primary) */
}

.company-add-edit-main .ant-input-affix-wrapper,
.company-add-edit-main .ant-input-affix-wrapper > input.ant-input,
.company-add-edit-main textarea.ant-input,
.company-add-edit-main .ant-picker,
.company-add-edit-main .ant-input-number {
    background: transparent;
    border-radius: 0px !important;
}

.company-add-edit-main .document-type-card {
    background: var(--white);
    box-shadow: var(--box-shadow-card);
}

.company-add-edit-main .ant-upload .ant-btn {
    /* border: 1px solid var(--primary); */
    /* height: 40px; */
    text-align: left;
    width: 100%;
}

.company-add-edit-main .ant-btn {
    border-radius: 0px;
}

