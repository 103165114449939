/* Application Configuration 

1. All title tags should be of <h2> and all subtitle tag should be of <h4> 
2.Font family ---> PopinRegular for all text
3.Font-weight ---> all title,subtitle,text should be font-weight of 400,buttons and cancel subscribtions font-weight of 600
4.Breakpoint to consider {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}*/

:root {
    
    --white: #fff;
    --green: green;
    --red: red;
    --primary: #1582D4;  
    --black: #000;
    --inpt-border: #c9c9c9;
    --gray: #dedede;
    --welcome-text:rgba(0, 0, 0, 0.5);
    --border-radius-round: 20px;
    --btn-border-radius: 4px;
    --card-border-radius: 6px;
    --input-box-color : 1px solid rgba(9, 44, 71, 0.2);
    --box-shadow-card:  0px 1px 29px #B8B8B859;
    --secondary-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    --padding-one: 1px;
    --padding-two: 2px;
    --padding-three: 3px;
    --padding-four: 4px;
    --padding-five: 5px;
    --padding-six: 6px;
    --padding-seven: 7px;
    --padding-eight: 8px;
    --padding-nine: 9px;
    --padding-ten: 10px;
    --padding-eleven: 11px;
    --padding-tweleve: 12px;
    --padding-thirteen: 13px;
    --padding-fourteen: 14px;
    --padding-fifiteen: 15px;
     --padding-sixteen: 16px;
      --padding-seventeen: 17px;
     --padding-eifhteen: 18px;
     --padding-nineteen: 19px;
     --padding-twenty: 20px;
     --padding-twentyone: 21px;
     --padding-twentytwo: 22px;
     --padding-twentythree: 23px;
     --padding-twentyfour: 24px;
     --padding-twentyfive: 25px;
     --padding-twentysix: 26px;
     --padding-twentyseven: 27px;
     --padding-twentyeight: 28px;
     --padding-twentynine: 29px;
     --padding-thirty: 30px;
     --padding-one-em: 1em;
     --padding-two-em: 2em;
     --padding-three-em: 3em;
      --padding-four-em: 4em; 
      --padding-five-em: 5em;
      --padding-six-em: 6em;
      --padding-sixteen-em: 16em;

       --thirty-width: 30px;
       --fifty: 50px;
       --fourty: 42px;
       --chart-height: 230px;
        --card-height: 350px;
}

/*fonts*/


@font-face { 
    font-family: 'MontserratBlack';
    src: url(./assets//fonts/Montserrat/MontserratAlternates-Black.ttf) format("truetype");
}

@font-face {
    font-family: 'MontserratBlackItalic';
    src: url(./assets//fonts/Montserrat/MontserratAlternates-Italic.ttf) format("truetype");
}

@font-face {
    font-family: 'MontserratLight';
    src: url(./assets//fonts/Montserrat/MontserratAlternates-Light.ttf) format("truetype");
}

@font-face {
    font-family: 'MontserratLightItalic';
    src: url(./assets//fonts/Montserrat/MontserratAlternates-LightItalic.ttf) format("truetype");
}

@font-face {
    font-family: 'MontserratMedium';
    src: url(./assets//fonts/Montserrat/MontserratAlternates-Medium.ttf) format("truetype");
}

@font-face {
    font-family: 'MontserratMediumItalic';
    src: url(./assets//fonts/Montserrat/MontserratAlternates-MediumItalic.ttf) format("truetype");
}

@font-face {
    font-family: 'MontserratBlackLight';
    src: url(./assets//fonts/Montserrat/MontserratAlternates-Light.ttf) format("truetype");
}

@font-face {
    font-family: 'MontserratSemiBold';
    src: url(./assets//fonts/Montserrat/MontserratAlternates-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: 'MontserratSemiBold';
    src: url(./assets//fonts/Montserrat/MontserratAlternates-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: 'MontserratThin';
    src: url(./assets//fonts/Montserrat/MontserratAlternates-Thin.ttf) format("truetype");
}

@font-face {
    font-family: 'MontserratThinItalic';
    src: url(./assets//fonts/Montserrat/MontserratAlternates-ThinItalic.ttf) format("truetype");
}

@font-face {
    font-family: 'NotoBold';
    src: url(./assets//fonts/Notosans/NotoSans-Bold.ttf) format("truetype");
}

@font-face {
    font-family: 'NotoBoldItalic';
    src: url(./assets//fonts/Notosans/NotoSans-BoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: 'NotoItalic';
    src: url(./assets//fonts/Notosans/NotoSans-Italic.ttf) format("truetype");
}

@font-face {
    font-family: 'NotoRegular';
    src: url(./assets//fonts/Notosans/NotoSans-Regular.ttf) format("truetype");
}

body {
    display: block !important;
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;
    background: #fff;
    max-width: 1930px !important;
    margin: auto !important;
    margin-left: -1px !important;
    overflow-x: hidden !important;
}

.container {
    width: 90%;
    display: block;
    margin: 140px auto auto auto;
    overflow-x: hidden;
    min-height: 90vh;
}

.page-main-heading {
    color: #092D47 !important;
 
}

.primary-button {
    background: var(--primary) !important;
    color: var(--white) !important;
    /* font-family: PopinRegular; */
  
}

.primary-button:hover {
    background: var(--primary) !important;
    border-radius: 0px;
    color: var(--white) !important;
    /* font-family: PopinRegular; */

}
/* Button Styling */
/* Gradient button */
.ant-btn-primary, 
.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus {
    background-image: linear-gradient(to right,#092C47, #2F80ED);
    border:none;
    box-shadow:none;
    color:var(--white);
    font-family: NotoRegular;
    height: 38px;
    text-shadow:none;
    width: 100%;
}

.back-complete-btn {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: 2em;
}

.anchor-buttons {
    color: var(--primary);
    font-family: 'POPINMEDIUM';
    margin-right: var(--padding-one-em);
    text-decoration: underline;  
}
.right-align-buttons {
    text-align: right !important;
}
/***Forms**/
.ant-form-item-has-error :not(.ant-input-disabled).ant-input, 
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper, 
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover, 
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
    border-color: var(--inpt-border);
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus, 
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus,
 .ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused,
 .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
     box-shadow: none;
 }

 .ant-form-item-has-error .ant-input-prefix {
     color: var(--inpt-border);
 }
 
 .ant-form-item-label,
 .ant-input-affix-wrapper > input.ant-input {
      /* font-family: PopinRegular; */ 
      color: #565656;
      font-family: 'NotoRegular';
 }

 /**Radio buttons*/
 .ant-radio-inner::after {
     background-color: var(--primary);
 }

 .ant-radio-checked .ant-radio-inner {
    border-color: var(--primary);
 }

 /****Table css**/
 .ant-table-thead > tr > th {
     background: var(--primary);
     color: var(--white);
     font-family: NotoBold;
 }

 .ant-table-tbody > tr > td {
     color: var(--primary) !important;
    font-family: NotoRegular;
 }
 
 .ant-table-tbody > tr:hover {
     background: transparent !important;
 }

 .ant-table-tbody > tr.ant-table-row:hover > td {
     background: var(--white);
 }

 .ant-table table {
     border:  1px solid var(--inpt-border);
 }

 .header-logout label {
     display: none;
 }
 
 .ant-pagination-item-active a,  .ant-pagination-item-active a:hover{
     color: var(--white);
     text-decoration: none !important;
 }

 .ant-pagination-item-active, 
 .ant-pagination-item-active:hover {
     border: 0px;
     background: var(--primary);
     border-radius: var(--border-radius-round);
 }

 .ant-pagination-item {
     border: 0px;
 }

 /* Hides the input number range */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Srollbar styeling */
::-webkit-scrollbar {
    width: 6px;
}
   
::-webkit-scrollbar-track {
    background-color: darkgrey;
}
   
::-webkit-scrollbar-thumb {
    background-color: var(--primary) !important;
    outline: 1px solid var(--primary) !important; 
}

input[type=number] {
    -moz-appearance: textfield;
}

.ant-input-number-handler-wrap {
    display: none !important;
}

.entity-check-radio-sec {
    text-align: right;
}


 /**Animations*/
 @keyframes left-to-right {
    0%   {transform: translateX(-20px); opacity: 0;}
    70%  {transform: translateX(-20px); opacity: 0;}
    100% {transform: translateX(0px); opacity: 1;}
}

.ani-left-to-right {
    animation-name: left-to-right;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-direction: alternate;
}

.ani-bottom-to-top{
    animation-name: bottom-to-top;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-direction: alternate;
}

.ani-top-to-bottom{
    animation-name: top-to-bottom;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-direction: alternate;
}

@keyframes top-to-bottom {
    0%   {transform: translateY(-20px); opacity: 0;}
    70%  {transform: translateY(-20px); opacity: 0;}
    100% {transform: translateY(0px); opacity: 1;}
  }

@keyframes bottom-to-top {
    0%   {transform: translateY(20px); opacity: 0;}
    70%  {transform: translateY(20px); opacity: 0;}
    100% {transform: translateY(0px); opacity: 1;}
}
  
/*changing colors to menu in mobile view**/
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
    color: var(--primary) !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
    content: none !important;
}

/**Select***/
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0px  !important;
    cursor: pointer;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
    color: #565656;
    cursor: pointer;
    line-height: 29px;
    padding-left: 10px;
    font-family: 'NotoRegular';
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: var(--gray);
    padding-left: 10px;
}

.ant-form-item-explain.ant-form-item-explain-error {
    font-family: NotoRegular;
    line-height: 1.2;
    font-size: 12px;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent !important;
}

/**Time line css**/
.ant-timeline-item {
    padding-bottom: 0px !important;
    padding-top: 6px !important;
}

.ant-timeline {
    min-width: 300px;
}

/***breadcrum css***/

.ant-breadcrumb span {
    color: #000;
    font-family: NotoRegular;
    font-weight: 500;
}

.ant-breadcrumb .ant-breadcrumb-link {
    font-family: 'NotoRegular';
    font-variant: initial;
    margin-bottom: 10px;
    color: #b8b8b8 !important;
    cursor: pointer;
}


.bread-crumb-a,
.bread-crumb-a:hover {
    cursor: pointer;
    color: #B8B8B8 !important;
    font-family: NotoRegular;
    text-decoration: none;
}

/**pagination css*/
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    border: none !important;
}

/**Search css***/
.ant-input-search-button {
    background: var(--primary);
}



.ant-input-search-button svg {
    color: var(--white);
}

.search-filter .ant-btn:hover,
.search-filter .ant-btn:focus {
    background:  linear-gradient(to right,#092C47, #2F80ED);
    background-color:  linear-gradient(to right,#092C47, #2F80ED);
    border-color: var(--primary);
    box-shadow: none;
}

/****Entity Modal css***/
.ant-modal-title {
    font-family: NotoBold;
}
.entity-modal {
    width: 750px !important;
}

.entity-modal .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    /* height: 40px; */
}

.entity-modal .ant-upload-select {
    width: 100%;
}

.entity-modal .ant-upload-select .ant-btn {
    width: 100% !important;
    height: 34px !important;
}

.entity-modal .ant-modal-body {
    padding: 24px 70px;
}

.modal-form-sec .ant-btn-primary {
    background: var(--primary);
}

.ant-modal-title {
    font-weight: 500;
}

.no-data-drawer {
    font-weight: 500;
    margin-top: 2em;
    text-align: center;
}

.ant-table-cell img {
    height: 100px;
    object-fit: contain;
    width: 100px;
}

 .ant-steps-navigation .ant-steps-item::after {
    display: none;
}

.ant-steps-item .ant-steps-item-wait {
    position: relative;
}
.ant-steps-item .ant-steps-item-wait::before {
    position: absolute;
}

.ant-steps-navigation .ant-steps-item-container {
    padding-top: 12px;
}

/****Admin Template****/ 
.amount-input p {
  color: var(--blue);
  font-weight: 500;
}

.account-modal .ant-select {
    height: 40px !important;
    background: transparent !important;
    border: 1px solid #092C47 !important;
}

.template-modal-row {
    align-items: center;
}

.account-modal .primary-button {
    height: 44px !important;
    width: 100%;
    margin-top: 5px;
}

.account-modal  .ant-input-affix-wrapper:hover {
    border: 1px solid #092C47 !important;
    background: var(--white);
    height: var(--fourty);
}

.account-modal .btn-box {
    width: 100%;
}

/***responsive for only mobiles***/
@media screen and (max-width: 767px) {
    .hloding-invester .logo-name-img img {
        max-width: 300px !important;
    }
    .container {
        margin-top: 90px !important;
    }
    .ant-drawer-left, .ant-drawer-right {
        top: 60px !important;
    }
    .ant-notification {
        top: 70px !important;
    }
    ::-webkit-scrollbar {
        display: none !important;
    }
    .investor-opp-card:hover,
.my-holdings:hover {
    transform: none !important;
    transition: all 300ms ease-in-out 0s;
    /* box-shadow: var( --box-shadow-card); */
}
    .invest-as-details .ant-col-offset-8{
        margin-left: 0px !important;
    }
    .price-col {
        width: 100% !important;
    }
    .profile-section .save-btn-sec {
        width: 100% !important;
    }
    .investor-opp-card,
    .my-holdings {
        margin: 3px;
    }

    .holdings-card-col {
        width: 100% !important;
    }

    .login-register-title-h {
        width: 90% !important;
        font-size: 20px !important;
    }

    .investor-joint-investor-details {
        width: 100% !important;
    }

    .invest-main-row-sec {
      display: block !important;
    }

    #steps-investor-individual .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
        /* padding-left: 12px !important; */
    }
   

    .invester-individula-main .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item,
    .invester-individula-main .ant-steps-navigation.ant-steps-small .ant-steps-item-container {
       width: 100%;
       text-align: center;
    }
    
    .invester-individula-main .holdings-details-sub-sec {
        padding-left: 0px;
    }

    .profile-box .security-section div:first-child {
        width: 100% !important;
    }

    .profile-box .security-section {
        display: block !important;
    }
    
    .profile-box .security-section div:last-child {
        width: 100% !important;
    }
    
    .success-section {
        margin-top: var(--padding-one-em) !important;
    }

   .profile-card .ant-col-offset-2 {
       margin-left: 0px !important;
    }

    .profile-box .security-section label:last-child {
        max-width: 98%;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
    }

    .profile-card .security-section label {
        padding-right: 10px;
        padding-bottom: 5px;
    }

    .entity-modal .ant-col {
        width: 100%  !important;
    }

    .ant-tabs-tab {
        padding-right: 0px !important;
    }

    .my-holdings-main .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
        width: 100% !important;
    }

    .logo-text-sec {
        width: 100% !important;
    }

    .profile-email-text {
        font-size: .8em !important;
    }

    .login-register-title {
        font-size: 1.3em !important;
    }

    .investor-row {
        display: block !important;
        margin-right: 0px !important;
    }
    
    .app-header .header-logo {
        padding-right: 0px !important;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
        font-family: NotoRegular;
    }

    .pie-charts .ant-row .ant-col {
        padding-left: 0px !important;
        width: 100%;
    }

    .pie-charts,
    .admin-detail,
    .document-type-card {
        margin-left: 1% !important;
        margin-bottom: 2em;
        width: 98% !important;
    }
    
    .container-payment {
        width: 90% !important;
        margin-top: 90px !important;
    }

    .payment-table {
        box-shadow: var(--box-shadow-card);
        overflow: scroll;
    }
    
    .header-right-section {
        display: block !important;
        position: relative !important;
    }

    .profile-section .ant-col-offset-4 {
        margin-left: 0px !important;
    }

    .profile-section .ant-row {
        display: block;
    }
    
    .app-header .ant-menu-horizontal > .ant-menu-submenu {
        font-size: 1.8em;
        text-align: right;
        width: 20%;
    }

    .app-header .ant-menu-item {
        width: 80%;
    }

    .main-investor {
        width: 285px !important;
    }

    .ant-steps {
        width: 100% !important;
        overflow-x: scroll;
    }

}


.ant-notification-notice-description {
    padding-right: 2em;
}

.mobile {
    display: none !important;
}

.add-entity-country-code .ant-select {
    width: 70px !important;
}

/***Tabs css**/
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list  {
    justify-content: flex-start;
    padding-left: 1em;
    text-align: center;
    width: 100%;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {   
    color: var(--primary);
    font-size: 1.3em;
    font-weight: 700;
}

.ant-tabs-tab-btn {
    color: var(--gray);
    font-size: 1em;
    font-weight: 500;
}

.ant-tabs-tab {
    padding-right: 6em;
}

.ant-tabs-ink-bar {
    display: none;
}

.ant-radio-wrapper {
    font-weight: 500;
}

/****Responive for mobiles, tab, and low resolution desktops***/
@media screen and (max-width: 1000px) {

    .holdings-details-sub-sec {
        width: 100% !important;
    }
    
    .hloding-invester {
        width: 95% !important;
    }
    
    .profile-box {
        width: 99% !important;
    }

    .ant-table {
        overflow-x: scroll;
    }

    .header-right-section {
        display: block !important;
        position: relative !important;
    }

    .header-logout img {
        width:11%;
    }

    .header-logout label {
        display: block;
    }
   
   
    .ant-drawer-right .ant-drawer-content-wrapper {
        width: 280px !important;
    }

    .add-opportunity-main .document-type-card .card-main {
        padding: var(--padding-one-em) !important;

    }

    .add-opportunity-main .ant-col, 
    .view-opportunity-main .ant-col {
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 100%;
        
    }

    .or-section:after,
    .or-section:before {
        width: 100px !important;
    }

    .entity-id span {
        float: left !important;
    }
}

/***css for drower for large devices***/
@media only screen and (min-width: 2000px) {
    iframe {
        right: 20% !important;
    }
       
}

.ant-upload .ant-btn span {
    color: rgba(0, 0, 0, 0.85) !important;
}

.login-register-title-h {
    display: block;
    margin: 1em auto;
    color: #092D47;
    font-size: 30px;
    line-height: 1.4;
    position: relative;
    width: 35%;
    text-align: center;
    text-transform: capitalize;
    font-family: 'NotoRegular';
}

.login-register-title-h::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    height: 3px;
    background: #EEEEEE 0% 0% no-repeat padding-box;
    color: #fafafa;
    bottom: -10px;
}

.login-register-title-h span {
    color: var(--primary);
}

.join-investor-h {
    width: 80%;
    margin-left: 10%;
    font-family: NotoRegular;
}

.documents-row .ant-table-wrapper {
    display: block;
    margin: auto;
    width: 98% !important;
}

.documents-row .ant-table-pagination.ant-pagination {
    display: none;
}

.rdw-editor-main {
    /* border: 1px solid #dedede; */
    border-top: 0px;
    padding: 1em;
}

.document-type-card .rdw-editor-main {
    border: 1px solid #dedede;
}

.rdw-editor-toolbar {
    margin-bottom: 0px !important;
}

.add-investor-card {
    box-shadow: var( --box-shadow-card);
    background: var(--white);
    padding: 2em;
}

.anticon-close-circle {
    color: red;
    cursor: pointer;
}

.anticon-check {
    color: green;
    cursor: pointer;
}

/***responsive for only tablets***/
@media screen and (max-width: 1024px) {
    .deal-investment-section .container {
        margin-top: 56px !important;
    }
    .investor-opp-card:hover,
    .my-holdings:hover {
        transform: none !important;
        transition: all 300ms ease-in-out 0s;
        /* box-shadow: var( --box-shadow-card); */
    }
    .ant-notification {
        top: 65px !important;
    }
  
} 

.document-type-card .ant-table-wrapper {
    margin-top: 2em;
}


#anvil-signature-frame {
    width: 100% !important;
    height: 500px !important;
    display: block;
    margin: 120px auto 30px auto !important;
    min-height: 90vh;
}

.jGdBhH {
    max-width: 92% !important;
}

