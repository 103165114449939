.loading-main {
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    height: 100vh;
    left: 0px;
    position: fixed;
    top: 0%;
    width: 100%;
    z-index: 9999;
    overflow: hidden;
}

.spinner {
    animation: spin 2s linear infinite;
    border: 4px solid #dedede;
    border-top-color: var(--primary);
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
